.logo {
    height: 32px;
    background: rgba(255,255,255,.2);
    margin: 16px;
  }
  
  .header-menu span{
    margin-left: 10px;
    margin-right: 20px;
    cursor: pointer; 
    width: 100%
  }
  
  .version {
    color: white;
    background-color: black;
    box-shadow: 1px 1px 1px grey;
    padding: 10px;
    width: 115px;
    position: fixed;
    bottom: 10px;
    left:10px;
    border-radius: 5px;
  }
  
  #components-layout-demo-custom-trigger .trigger {
    font-size: 18px;
    line-height: 64px;
    padding: 0 24px;
    cursor: pointer;
    transition: color 0.3s;
  }
  
  #header {
    position:fixed;
    width:100%;
    left:0;
    top:0;
    right: 0;
    z-index: 1000;
}
